import { createTheme } from '@mui/material/styles';
import MontserratRegular from './assets/fonts/Montserrat/Montserrat-Regular.ttf';
import OswaldRegular from './assets/fonts/Oswald/Oswald-Regular.ttf';

const theme = createTheme(
  {
    palette: {
      common: {
        black: '#000000',
        white: '#FFFFFF',
        outline: '#D9D9D9',
        grey: '#8F8F8F',
      },
      primary: {
        main: '#2A7CB5',
        light: '#CDEBFF',
        light3D:'#A9C9DC',
      },
      secondary: {
        main: '#0C3752',
      },
      error: {
        main: '#BC4400',
        light: '#FFAD73',
        dark:"#803614"
      },
      warning: {
        main: '#FFB74A',
        light: '#FFDA7C',
        dark:'#D69A3E'
      },
      success: {
        main: '#8BD977',
        light: '#D2FFC6',
        dark:'#245518'
      },
      info: {
        main: '#747679',
        light:'#ACAEB1',
        dark:'#404246'
      },
      grey: {
        A100: '#d5d5d5'
      },
      favorite: {
        light: '#E1F2FF'
      },
      background: {
        default: "#fff"
      },
      tiles: {
        overdue: "#D90429",
        inProgress: "#3A86FF",
        pending: "#2EC4B6",
        operations: "#03045E",
        notifications: "#FFBE0B",
      },
      chips: {
        background: {
          new: "#CFC3FF",
          modify: "#C6F3E9",
          delete: "#FBDFB2",
          overdue: "#C80909"
        },
        textColor: {
           primary: "#1D1D1D"
        } 
      }
    },
    typography: {
      fontFamily: "'Montserrat-Regular', 'Oswald-Regular', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: 12,
    },
    props: {
      MuiButton: {
        disableRipple: true,
        label: {
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '18.75px',
        }
      },
      MuiNativeSelect: {
        disableUnderline: true,
        select: {
          "&: focus": {
            backgroundColor: 'blue',
          }
        }
      },
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiTypography: {
        button: {
          backgroundColor: '#000'
        },
        body1: {
          fontSize: '16px',
          color: '#1D1D1D'
        },
        colorTextPrimary: {
          color: '#fff',
          fontSize: '14px',
          fontWeight: '400',
        }
      },
      MuiSvgIcon: {
        colorError: {
          color: '#C90A0A',
        },
      },
      MuiListItemText: {
        primary: {
          "&$selected": {
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "orange",
            },
          },
        },
      },
      MuiCard: {
        root: {
          marginRight: '14px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)'
        }
      },
      MuiFormControl: {
        root: {
        }
      },
      MuiPopover: {
        paper: {
          padding: '10px',
        }
      },
      MuiInput: {
        root: {
          border: '1px solid rgba(143, 143, 143, 1)',
          borderRadius: '4px',
          padding: '5px 15px',
        },
        formControl: {
          marginTop: '30px !important',
        },
      },
      MuiInputLabel: {
        root: {
          color: '#1D1D1D',
          fontSize: '20px',
          fontWeight: '400',
          lineHeight: '18.75px'
        },
      },
      MuiFormLabel: {
        root: {
          color: '#1D1D1D',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '18.75px',
          paddingBottom: '10px'
        },
      },
      MuiButton: {
        label: {
          borderRadius: '8px',
          padding: '3px 10px 3px 10px',
        }
      },
      MuiIconButton: {
        root: {
          padding: '20px'
        }
      },
      MuiTablePagination: {
        root: {
          justifyContent: 'center',
          display: 'flex'
        }
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          boxShadow: '2px 0 16px 0 rgba(95,140,202,0.24)',
          borderRight: '0px'
        },
        paperAnchorRight: {
          width: '475px'
        }
      },
      MuiBreadcrumbs: {
        root: {
          color: '#fff',
          fontSize: '14px',
          fontWeight: '400',
        }
      },
      MuiTabs: {
        flexContainer: {
          justifyContent: 'space-around',
        }
      },
      MuiTableHead: {
        root: {
          backgroundColor: '#f4f4f4',
        },
      },
      MuiTableCell: {
        body: {
          color: 'rgba(29, 29, 29, 1)',
          fontWeight: '400',
          lineHeight: '22px',
          fontSize: '14px'
        },
          head: {
            fontSize: '16px',
            fontWeight: '500',
            color: 'rgba(29, 29, 29, 0.6)'
          },
        },
      MuiCssBaseline: {
        '@global': {
          body: {
            color: '#1D1D1D',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '22px',
            fontFamily: 'MontserratRegular',
          },
          h1: {
            color: '#fff',
            fontSize: '48px',
            fontWeight: '700',
            lineHeight: '48px',
            fontFamily: 'OswaldRegular',
          }
        },
      },
    }
  }
);

export default theme;