import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import styles from "./styles";
import GroupedAutocomplete from "./../Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getSearch } from "../../redux/actions/Search/search";
import { getAutoComplete } from "../../redux/actions/Autocomplete/autocomplete";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import { Box } from "@mui/material";
import { getTrainualData } from "../../redux/actions/Trainual/trainual";
import TrainingCard from "../TrainingCard";
import Loader from "../Loader";

export default function Search({ setOpenDialog }) {
  const theme = useTheme();
  const { classes } = styles();

  const oAuthToken = localStorage.getItem("oAuthToken");
  const [searchQuery, setSearchQuery] = useState("");
  const [queryCategory, setQueryCategory] = useState("");
  const [options, setOptions] = useState([]);
  const [trainingData, setTrainingData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { autoComplete } = useSelector((state) => state.autoComplete);
  const { trainual, status, loading, error } = useSelector(
    (state) => state.trainual
  );

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length >= 3 && oAuthToken) {
      dispatch(
        getAutoComplete({
          searchQuery: e.target.value,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (autoComplete && autoComplete.length > 0) {
      // Grouping autoComplete array by categories while maintaining the original order
      const groupedAutoComplete = autoComplete.reduce((acc, item) => {
        const category = item.categories.toUpperCase();
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      }, {});

      // Flattening the grouped results into an array while preserving order
      const sortedAutoComplete = Object.keys(groupedAutoComplete).reduce(
        (acc, category) => {
          return acc.concat(groupedAutoComplete[category]);
        },
        []
      );

      // Mapping sortedAutoComplete to options
      setOptions(
        sortedAutoComplete.map((option) => ({
          firstLetter: /[0-9]/.test(option.categories.toUpperCase())
            ? "0-9"
            : option.categories.toUpperCase(),
          ...option,
        }))
      );
    } else {
      setOptions([]);
    }
  }, [autoComplete]);

  useEffect(() => {
    if (oAuthToken === null || oAuthToken === undefined) {
      dispatch(fetchAndStoreToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (oAuthToken) {
      dispatch(
        getTrainualData({
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
    }
  }, [dispatch, oAuthToken]);

  useEffect(() => {
    if (trainual) {
      setTrainingData(trainual);
    }
  }, [trainual]);

  const handleBlur = () => {
    setOptions([]);
  };
  const handleFocus = (event) => {
    if (event.target.value < 3) setOptions([]);
  };

  const handleSearch = (e) => {
    setOpenDialog(false);
    navigate(`/SearchResult?srh_key=${searchQuery}&category=${queryCategory}`);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (oAuthToken && searchQuery.length > 0) {
        await dispatch(
          getSearch({
            searchQuery,
            page: 0,
            oAuthToken: localStorage.getItem("oAuthToken"),
          })
        );
        await setOptions([]);
        handleSearch();
      }
    }
  };
  const handleSearchClick = async () => {
    if (oAuthToken && searchQuery.length > 0) {
      await dispatch(
        getSearch({
          searchQuery,
          page: 0,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
      await setOptions([]);
      handleSearch();
    }
  };

  return (
    <>
      <Box sx={{}} className={classes.root}>
        {/* <FormControl fullWidth sx={{ m: 1 }} variant="standard">
        <InputLabel htmlFor="standard-adornment-amount">Search</InputLabel>
          <Input
            className={classes.searchInput}
            startAdornment={
                <InputAdornment position="end">
                    <IconButton onClick={handleSearch}><SearchIcon/></IconButton>
                    <IconButton><MicIcon /></IconButton>
                </InputAdornment>}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          />
        </FormControl> */}
        <GroupedAutocomplete
          options={options}
          onChange={(e) => {
            handleInputChange(e);
          }}
          onClick={handleSearchClick}
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          onKeyPress={handleKeyPress}
          value={searchQuery}
          setOpenDialog={setOpenDialog}
        />
      </Box>
      <Box className={classes.trainualContainer}>
        {/* <Box className={classes.trainualMessage}>Select a category to find the assistance you need</Box> */}
        {loading ? <Loader isDashboard={true} /> : ""}
        {!loading && <TrainingCard trainingData={trainingData} setOpenDialog={setOpenDialog} />}
      </Box>
    </>
  );
}
