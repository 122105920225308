import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => {
  return {
    dialog: {  
      '& .MuiDialog-paper':{
        maxWidth: "800px !important",
        borderRadius:"8px"
      }
    },
    dialogBody:{
      display: "flex", 
      justifyContent: "space-between",
      flexDirection:"row",
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column"
      },
    },
    dialogBodySection1:{
      flex: 1,
      paddingRight: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Center align the content horizontally
      paddingTop: "16px",
      paddingBottom: "16px",
      [theme.breakpoints.down('sm')]: {
        borderBottom: "1px solid #ddd", // Ensure border is there on mobile
        paddingRight: "0", // Adjust padding for mobile
        paddingLeft: "none",
      },
    },
    dialogBodySection2:{
      borderLeft: `1px solid #ddd`,
      paddingLeft: "10px",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Center align the content horizontallyS
      paddingTop: "16px",
      paddingBottom: "16px",
      [theme.breakpoints.down('sm')]: {
        borderLeft: "none", // Remove the border from the last section
        paddingRight: "10px",
        paddingLeft: "none",
      },
    },
    dialogBoxTitle: {
      fontFamily: "Montserrat-Regular",
      fontWeight: "600",
      fontSize: theme.spacing(3),
      color: theme.palette.info.dark,
      // padding:'8px 16px',
      padding:'8px 16px 8px 192px', // for Title in center
      [theme.breakpoints.down('sm')]: {
        padding:'8px 16px',
      },
      backgroundColor:theme.palette.primary.light,
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)", /* 3D shadow effect */
      transition: "box-shadow 0.3s ease, transform 0.2s ease",
    },
    dialogBoxDescription: {
      fontFamily: "Montserrat-Regular",
      fontSize: theme.spacing(2),
      fontWeight: "600",
      color: theme.palette.info.main,
      paddingLeft:'12px'
    },
    dialogBoxActivityButton: {
      fontFamily: "Montserrat-Regular",
      fontSize: theme.spacing(2),
      fontWeight: "500",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "24px",
      textTransform: "capitalize",
      color: theme.palette.common.white,
      margin: "8px",
      cursor:'pointer',
      padding: "6px 16px",
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08)", /* Deeper shadow on hover */
        transform: "translateY(-1px)" /* Slightly lifts the button on hover */
      },
      '&:active': {
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08)", /* Deeper shadow on hover */
        transform: "translateY(1px)" /* Slightly lifts the button on hover */
      },
      width:'360px',
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)", /* 3D shadow effect */
      transition: "box-shadow 0.3s ease, transform 0.2s ease",
    },
  };
});

export default styles;
