import { createSlice } from "@reduxjs/toolkit";
import { fetchAndStoreToken } from "../../actions/oAuthToken/oAuthToken";

const oAuthTokenSlice = createSlice({
  name: "oAuthToken",
  initialState: {
    oAuthToken: null,
    loading: false,
    error: null,
    tokenFetched: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAndStoreToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAndStoreToken.fulfilled, (state, action) => {
        state.loading = false;
        state.tokenFetched = true;
        state.oAuthToken = action.payload;
        state.error = null;
      })
      .addCase(fetchAndStoreToken.rejected, (state, action) => {
        state.loading = false;
        state.tokenFetched = false;
        state.error = action.error.message;
      });
  },
});

export default oAuthTokenSlice.reducer;
