import React, { useEffect, useState } from "react";
import Header from "../Header/Index";
import Footer from "../Footer";
// import slider2 from "../../assets/images/slider/slider2.png";
import { Box, Grid, useMediaQuery } from "@mui/material";
import AttachmentViewer from "../AttachmentViewer";
import RelatedContentSlider from "../RelatedContentSlider";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDetailPageData } from "../../redux/actions/detailPage/detailPage";
import styles from "./styles";
import "./style.scss";
import VideoViewer from "../VideoViewer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import Loader from "../Loader";
import CustomizedSnackbars from "../Snackbar/index.js";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { useTheme } from "@emotion/react";
import FavoriteIconButton from "../AppCard/FavoriteIconButton";
import {
  addFavoriteContents,
  fetchFavorites,
  removeFavoriteContents,
} from "../../redux/actions/graph/favorites";
import { getUserData } from "../../redux/actions/userData/userData";
import { getAttachment } from "../../redux/actions/Attachment/attachment.js";

const style = {
  content: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#CDEBFF",
    opacity: "0.85",
  },
};

const DetailPage = () => {
  const { classes } = styles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");
  const detail_page_api_path = window.location.pathname;
  const baseUrl = window.location.origin;
  const favoritesContents = useSelector((state) => state.getFavoritesContents);
  const userId = useSelector((state) => state.userData.userData.id);
  const [detailData, setDetailData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const theme = useTheme();

  const handleLinkClick = (e) => {
    e.preventDefault();
    let href = null;
    if (e.target.href) {
      href = e.target.getAttribute("href");
    } else if (e.target.parentElement.href) {
      href = e.target.parentElement.getAttribute("href");
    }
    if (!href) {
      e.preventDefault();
      return;
    }
    if (
      href.startsWith("http") ||
      href.startsWith("https") ||
      href.startsWith("www") ||
      href.startsWith("//")
    ) {
      window.open(href, "_blank");
    } else if (href.startsWith("mailto")) {
      window.location.href = href;
    } else {
      navigate(href, { replace: false });
    }
  };

  const title = detailData ? detailData.title : "";
  const summary = detailData ? detailData.summary : "";
  const description = detailData && detailData.description;

  const finalDescription = description?.replace(/<div class="field__label visually-hidden">.*?<\/div>|<img([^>]*)>|<article([^>]*)>/g,
    (match, imgAttrs, articleAttrs) => {
      if (imgAttrs) {
        // Handle <img> tag: Add inline style
        return `<img style="max-width: 100%; height: auto;"${imgAttrs}>`;
      }
      if (articleAttrs) {
        // Handle <article> tag: Remove Attributes
        return `<article>`;
      }
      // Handle <div class="field__label visually-hidden">: Remove it
      return '';
    }
  );

  const attachment =
    detailData && detailData.attachment ? detailData.attachment : null;
  const thumbnail =
    detailData && detailData.thumbnail ? detailData.thumbnail : null;
  const internal_video =
    detailData && detailData.internal_video ? detailData.internal_video : null;
  const external_video =
    detailData && detailData.external_video ? detailData.external_video : null;
  const external_Quicklinks =
    detailData && Array.isArray(detailData.quick_links)
      ? detailData.quick_links[0].external_links
      : null;
  const internal_Quicklinks =
    detailData && Array.isArray(detailData.quick_links)
      ? detailData.quick_links[0].internal_links
      : null;
  const related_contents =
    detailData && Array.isArray(detailData.related_contents)
      ? detailData.related_contents[0].related_contents
      : null;
  const descriptionBoxFlag =
    (!description ||
      description === "" ||
      description === '<div className="custom_editor"></div>') &&
      (attachment || thumbnail || external_video || internal_video)
      ? true
      : false;
  const quickLinksBoxFlag =
    (Array.isArray(external_Quicklinks) ||
      Array.isArray(internal_Quicklinks)) &&
      (!description ||
        description === "" ||
        description === '<div className="custom_editor"></div>') &&
      (attachment || thumbnail || external_video || internal_video)
      ? true
      : false;
  const dispatch = useDispatch();
  const { detail, status, loading, error } = useSelector(
    (state) => state.detail
  );
  const oAuthToken = localStorage.getItem("oAuthToken");
  const [isTokenReady, setIsTokenReady] = useState(false);
  const { tokenFetched } = useSelector((state) => state.oAuthToken);

  useEffect(() => {
    // If the token is not available in localStorage, fetch and store it
    if (!oAuthToken) {
      dispatch(fetchAndStoreToken()); // Action to fetch and store the token in localStorage
    } else {
      setIsTokenReady(true); // Token is already in localStorage
    }
  }, [oAuthToken, dispatch]); // Dependency array includes `oAuthToken` so that it triggers when the token is updated in localStorage
  
  useEffect(() => {
    const tokenFromLocalStorage = localStorage.getItem("oAuthToken");
  
    if (tokenFromLocalStorage) {
      setIsTokenReady(true); // Token is ready, trigger API call
    } else {
      setIsTokenReady(false); // Token is still not available
    }
  }, [tokenFetched]);
  
  // Trigger the detail API call when the token is ready and API path is available
  useEffect(() => {
    if (isTokenReady && detail_page_api_path) {
      dispatch(
        getDetailPageData({
          detailAPI: detail_page_api_path,
          oAuthToken: oAuthToken, // Using the token from localStorage
        })
      );
    }
  }, [isTokenReady, detail_page_api_path, dispatch]);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [detail_page_api_path]);

  useEffect(() => {
    if (detail && detail.length == 0) {
      setDetailData(detail);
    } else if (detail && detail.length > 0) {
      setDetailData(detail[0]);
    }
  }, [detail]);

  const dataFetched = !loading && (detail?.length > 0 || detail?.length === 0 || error);

  const isFavorite = (detail_page_api_path) => {
    // Iterate over each object in the getFavorites array
    if (
      null != favoritesContents &&
      favoritesContents.getFavoritesContents &&
      undefined != favoritesContents &&
      favoritesContents.getFavoritesContents
    ) {
      if (
        favoritesContents.getFavoritesContents.favoritesContents?.includes(
          detail_page_api_path + "=" + title
        )
      ) {
        return true; // AppId is found, so it's a favorite
      }
    }
    return false; // AppId is not found in any favorites
  };
  const handleFavoriteChangeInDetailpage = (
    detail_page_api_path,
    isFavorite
  ) => {
    if (!isFavorite) {
      dispatch(
        addFavoriteContents({
          userId,
          contentId: detail_page_api_path + "=" + title,
        })
      ).then((res) => {
        if (res.payload && !res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    } else {
      dispatch(
        removeFavoriteContents({
          userId,
          contentId: detail_page_api_path + "=" + title,
        })
      ).then((res) => {
        if (res.payload && !res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    }
  };

  const fileSaveSuccessMsg = () => {
    return (
      <CustomizedSnackbars
        message="File saved successfully"
        vertical="top"
        horizontal="center"
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        attachment={true}
      />
    )
  }

  const fileSaveErrorMsg = () => {
    return (
      <CustomizedSnackbars
        message="Error saving the file."
        vertical="top"
        horizontal="center"
        open={openErrorSnackbar}
        setOpen={setOpenErrorSnackbar}
        failure={true}
        attachment={true}
      />
    )
  }

  const handleDownload = async (attachment) => {
    if (attachment) {
      const fileName = attachment && attachment.split('/').pop();
      try {
        // Fetch the file
        const response = await fetch(attachment);
        if (!response.ok) throw new Error("Failed to fetch file");
        // Convert to Blob
        const blob = await response.blob();
        // Create an object URL for the Blob
        const url = URL.createObjectURL(blob);
        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName; // Specify the filename
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Release memory
        setOpenSnackbar(true);
        console.log("File saved successfully")
      } catch (error) {
        console.error("Download failed:", error);
        setOpenErrorSnackbar(true);
      }
    }
  };

  // async function handleSaveFile(attachment) {
  //   const fileName = attachment && attachment.split('/').pop();
  //   try {
  //     const response = await dispatch(getAttachment(attachment)).unwrap();
  //     const blob = new Blob([response], { type: response.type || 'application/octet-stream' });
  //     // const response = await fetch(attachment);
  //     // if (!response.ok) throw new Error(`Failed to fetch file: ${response.statusText}`);
  //     // const blob = await response.blob();

  //     const fileHandle = await window.showSaveFilePicker({
  //       suggestedName: fileName,
  //       types: [
  //         {
  //           description: fileName.split('.').pop().toUpperCase() + ' File',
  //           accept: { [blob.type]: ['.' + fileName.split('.').pop()] },
  //         },
  //       ],
  //     });

  //     const writableStream = await fileHandle.createWritable();
  //     await writableStream.write(blob);
  //     await writableStream.close();
  //     setOpenSnackbar(true);
  //     console.log("File saved successfully")
  //   } catch (error) {
  //     console.error('Error saving file:', error);
  //     setOpenErrorSnackbar(true);
  //   }
  // }

  return (
    <>
      <Header />
      <Box
        className={classes.bannerimgBox}
      // sx={{
      //   backgroundImage: `url(${slider2})`,
      // }}
      >
        <Grid container spacing={4} style={style.content}>
          <Link style={{ zIndex: 1 }} to={-1}>
            <div className={classes.backArrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.1562H7.83L13.42 5.56625L12 4.15625L4 12.1562L12 20.1562L13.41 18.7463L7.83 13.1562H20V11.1562Z"
                  fill="#000000"
                />
              </svg>
              <div className={classes.backTxt}>Back</div>
            </div>
          </Link>
          <Grid item xs={6} xsOffset={1} className={classes.BannerItem1}>
            <h1 className={classes.BannerHeading}>{loading ? "" : title}</h1>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.BannerItem2}>{loading ? "" : summary}</div>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          height: "36px",
          backgroundColor: `${theme.palette.info.dark}`,
          opacity: "1",
        }}
      >
        {isMobile ? (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: "0px" }}
          >
            <Grid
              item
              style={{ paddingTop: "2px", position: "relative", paddingLeft: "0px" }}
            >
              {!loading && attachment && (
                <DownloadForOfflineRoundedIcon
                  variant="filled"
                  sx={{
                    color: `${theme.palette.secondary.main}`,
                    backgroundColor: "#CDEBFF",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleSaveFile(attachment)}
                  onClick={() => handleDownload(attachment)}
                />
              )}
              {/* <ShareRoundedIcon
                variant="filled"
                sx={{
                  color: `${theme.palette.secondary.main}`,
                  backgroundColor: "#CDEBFF",
                  padding: "5px",
                  marginRight: "12px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              /> */}
            </Grid>
            <Grid item>
              {" "}
              {!loading && title && (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    padding: "5px",
                    marginLeft: "auto",
                    marginTop: "-20px",
                    marginRight: "0px",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "50%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "-5px",
                      top: "-3.5px",
                    }}
                  >
                    <FavoriteIconButton
                      aria-haspopup="true"
                      isFavorite={isFavorite(detail_page_api_path)} // Pass isFavorite as a prop
                      isDetail={true}
                      onClick={() =>
                        handleFavoriteChangeInDetailpage(
                          detail_page_api_path,
                          isFavorite(detail_page_api_path)
                        )
                      } // Pass the handler function for favorite click
                      detailPagePath={detail_page_api_path}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ marginTop: "0px", justifyContent: "flex-end" }}>
            <Grid
              item
              style={{ paddingTop: "2px", position: "relative", paddingLeft: "0px" }}
            >
              {!loading && attachment && (
                <DownloadForOfflineRoundedIcon
                  variant="filled"
                  sx={{
                    color: `${theme.palette.secondary.main}`,
                    backgroundColor: "#CDEBFF",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleSaveFile(attachment)}
                  onClick={() => handleDownload(attachment)}
                />
              )}
              {/* <ShareRoundedIcon
                variant="filled"
                sx={{
                  color: `${theme.palette.secondary.main}`,
                  backgroundColor: "#CDEBFF",
                  padding: "5px",
                  marginRight: "12px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              /> */}
            </Grid>
            <Grid item>
              {" "}
              {!loading && title && (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    padding: "5px",
                    marginLeft: "auto",
                    marginTop: "-13px",
                    marginRight: "40px",
                    backgroundColor: "#CDEBFF",
                    borderRadius: "50%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "-5px",
                      top: "-3.5px",
                    }}
                  >
                    <FavoriteIconButton
                      aria-haspopup="true"
                      isFavorite={isFavorite(detail_page_api_path)} // Pass isFavorite as a prop
                      isDetail={true}
                      onClick={() =>
                        handleFavoriteChangeInDetailpage(
                          detail_page_api_path,
                          isFavorite(detail_page_api_path)
                        )
                      } // Pass the handler function for favorite click
                      detailPagePath={detail_page_api_path}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      {(loading || !dataFetched) ? <Loader /> : ""}
      {Array.isArray(external_Quicklinks) ||
        Array.isArray(internal_Quicklinks) ? (
        <Box className={classes.quickLinksDynamicHtmlBox}>
          <Grid
            container
            xs={12}
            className={isMobile ? classes.quickLinksResponsiveContainer : ""}
          >
            <Grid item xs={8} className={classes.quickLinksDynamicHtmlBoxGrid1}>
              {!loading && dataFetched && detail?.length === 0 && detailData?.length === 0 && (<h3 style={{ color: theme.palette.secondary.main }}> Content not available</h3>)}
              <span
                onClick={(e) => {
                  // Only trigger on elements that are links and have a href attribute
                  if (
                    e.target.tagName === "A" &&
                    e.target.getAttribute("href")
                  ) {
                    handleLinkClick(e);
                  }
                  if (
                    e.target.tagName === "U" ||
                    e.target.tagName === "EM" ||
                    (e.target.tagName === "STRONG" &&
                      e.target.parentElement.href)
                  ) {
                    handleLinkClick(e);
                  }
                }}
                dangerouslySetInnerHTML={{
                  __html: loading ? "" : finalDescription ? finalDescription : ""
                }}
              ></span>
              {
                !loading && (attachment || thumbnail) && quickLinksBoxFlag && (
                  <AttachmentViewer attachment={attachment} thumbnail={thumbnail} />
                )
              }
              {!loading && internal_video && quickLinksBoxFlag && <VideoViewer videoUrl={internal_video} />}
              {!loading && external_video && quickLinksBoxFlag && <VideoViewer videoUrl={external_video} />}
            </Grid>
            <Grid item xs={4} className={isMobile ? classes.quickLinksDynamicHtmlBoxGrid2Mobile : classes.quickLinksDynamicHtmlBoxGrid2}>
              <h1 className={classes.QuickLinksHeading}>Quick Links</h1>
              <div className={classes.QuickLinksUrlBox}>
                {!loading &&
                  external_Quicklinks &&
                  external_Quicklinks.map((link) => (
                    <div className={classes.QuickLinksUrl}>
                      {link.external_link.startsWith(baseUrl) ?
                        <Link
                          className={classes.QuickLinksUrlFont}
                          to={link.external_link.replace(baseUrl, '')}
                          state={link}
                        >
                          {link.external_title}
                        </Link> :
                        <a
                          className={classes.QuickLinksUrlFont}
                          target="_blank"
                          href={link.external_link}
                        >
                          {link.external_title}
                        </a>
                      }
                    </div>
                  ))}
                {!loading &&
                  internal_Quicklinks &&
                  internal_Quicklinks.map((link) => (
                    <div className={classes.QuickLinksUrl}>
                      <Link
                        className={classes.QuickLinksUrlFont}
                        to={link.detail_page_api_link}
                        state={link}
                      >
                        {link.content_title}
                      </Link>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>
        </Box >
      ) : (
        <Box
          className={
            descriptionBoxFlag
              ? classes.dynamicHtmlBox2
              : classes.dynamicHtmlBox
          }
        >
          {!loading && dataFetched && detail?.length === 0 && detailData?.length === 0 && (<h3 style={{ color: theme.palette.secondary.main }}> Content not available</h3>)}
          <span
            onClick={(e) => {
              // Only trigger on elements that are links and have a href attribute
              if (e.target.tagName === "A" && e.target.href !== "") {
                handleLinkClick(e);
              }
              if (
                e.target.tagName === "U" ||
                e.target.tagName === "EM" ||
                (e.target.tagName === "STRONG" && e.target.parentElement.href)
              ) {
                handleLinkClick(e);
              }
            }}
            dangerouslySetInnerHTML={{
              __html: loading ? "" : finalDescription ? finalDescription : "",
            }}
          ></span>
        </Box>
      )}
      {
        !loading && (attachment || thumbnail) && !quickLinksBoxFlag && (
          <AttachmentViewer attachment={attachment} thumbnail={thumbnail} />
        )
      }
      {!loading && internal_video && !quickLinksBoxFlag && <VideoViewer videoUrl={internal_video} />}
      {!loading && external_video && !quickLinksBoxFlag && <VideoViewer videoUrl={external_video} />}
      {
        !loading && Array.isArray(related_contents) && (
          <Box className={classes.relatedContentBox}>
            <Grid container spacing={2} padding="10px">
              <Grid item xs={12}>
                <h1 className={classes.relatedContentHeading}>Related Content</h1>
              </Grid>
            </Grid>
            <RelatedContentSlider relatedContents={related_contents} />
          </Box>
        )
      }
      <Footer id="footer-detail" />
      {openSnackbar && fileSaveSuccessMsg()}
      {openErrorSnackbar && fileSaveErrorMsg()}
    </>
  );
};

export default DetailPage;
