import _ from "lodash";

const config = {
  env: process.env.REACT_APP_STAGE,
  baseName: process.env.PUBLIC_URL,
  sessionTimeout: 1000 * 5 * 12 * 20, //20 min
  showPopupTime: 1000 * 5 * 12 * 1, //1 min

  drupalapiBaseUrl: process.env.REACT_APP_DRUPAL_API_ENDPOINT,
  portalapiBaseUrl: process.env.REACT_APP_PORTAL_API_ENDPOINT,
  graphapiBaseUrl: process.env.REACT_APP_GRAPH_API_BASE_URL,
  REDIRECT_URL: process.env.REACT_APP_IDENTITY_REDIRECT_URL,
  pool_id: process.env.REACT_APP_DRUPAL_API_ENDPOINT,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  domain_name: process.env.REACT_APP_IDENTITY_ISSUER_1,
  googleTagManagerID: process.env.REACT_APP_GTAG,
  appInsightsConnectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING
};
export default config;
