import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import styles from "../SearchResult/styles";
import { Grid, Box } from "@mui/material";
import "../SearchResult/style.scss";
import slider2 from "../../assets/images/slider/slider2.png";
import Header from "./../../components/Header/Index";
import Footer from "./../../components/Footer";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSearch } from "../../redux/actions/Search/search";
import SearchPagination from "../SearchResult/SearchPagination";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import Loader from "../../components/Loader";

export default function NewSearchResult() {
    const theme = useTheme();
    const { classes } = styles();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState("");
    const [queryCategory, setQueryCategory] = useState([]);
    const [page, setPage] = useState(0);
    const [srhKey, setSrhKey] = useState("");
    const [linkTitle, setLinkTitle] = useState(null);
    const { search, loading } = useSelector((state) => state.search);
    const fetched = useSelector((state) => state.search.fetched);
    const oAuthToken = localStorage.getItem("oAuthToken");
    const [options, setOptions] = useState([]);
    const [updateUrl, setUpdateUrl] = useState(false);
    const selectedValues = useSelector(
        (state) => state.searchFilter.selectedValues
    );
    const dispatch = useDispatch();

    // useEffect(() => {
    //     console.log("USE_Effect_Launched");
    //     const title = localStorage.getItem('title');
    //     console.log("title1111", title);

    //     if (title) {
    //         console.log("title_Launched");
    //         console.log("title2222", title);

    //         setLinkTitle(JSON.parse(title));
    //     }
    // }, []);
    // console.log("linkTitleMain", linkTitle);
    // console.log("LocalStorage", localStorage);

 const [isTokenReady, setIsTokenReady] = useState(false);
  const { tokenFetched } = useSelector((state) => state.oAuthToken);

  useEffect(() => {
    // If the token is not available in localStorage, fetch and store it
    if (!oAuthToken) {
      dispatch(fetchAndStoreToken()); // Action to fetch and store the token in localStorage
    } else {
      setIsTokenReady(true); // Token is already in localStorage
    }
  }, [oAuthToken, dispatch]); // Dependency array includes `oAuthToken` so that it triggers when the token is updated in localStorage
  
  useEffect(() => {
    const tokenFromLocalStorage = localStorage.getItem("oAuthToken");
  
    if (tokenFromLocalStorage) {
      setIsTokenReady(true); // Token is ready, trigger API call
    } else {
      setIsTokenReady(false); // Token is still not available
    }
  }, [tokenFetched]);
  

    useEffect(() => {
        if (search.total_records > 0) setOptions([]);
    }, [search.search_results]);

    useEffect(() => {
        updateQueryString();
    }, [selectedValues, page]);

    useEffect(() => {
        if (oAuthToken && srhKey.length > 0) {
            setOptions([]);
            if (
                queryCategory === "" ||
                queryCategory === undefined ||
                queryCategory === null ||
                queryCategory.length === 0
            ) {
                dispatch(
                    getSearch({
                        searchQuery,
                        page: page,
                        oAuthToken: localStorage.getItem("oAuthToken"),
                    })
                );
            } else {
                const queryStringArray = splitQueryCategory(queryCategory);
                const queryString = queryStringFromArray(queryStringArray);
                dispatch(
                    getSearch({
                        searchQuery,
                        queryString,
                        page: page,
                        oAuthToken: localStorage.getItem("oAuthToken"),
                    })
                );
            }
        }
    }, [srhKey, queryCategory, isTokenReady]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const title = searchParams.get("title");
        const query = searchParams.get("srh_key");
        const searchQueryCategory = searchParams.get("category");
        if (query) {
            setLinkTitle(title);
            setSrhKey(query);
            setSearchQuery(query);
            setQueryCategory(searchQueryCategory);
        }
    }, [location.search]);

    function updateQueryString() {
        const newUrlAfterFilter = new URL(window.location);
        let queryStringCategory = [];
        selectedValues &&
            selectedValues.map((value) => {
                queryStringCategory.push(value);
            });
        // Construct the query string category parameter
        const queryStringCategoryParam = queryStringCategory
            .map((value) => {
                if (typeof value === "object" && value !== null) {
                    return `${Object.keys(value)[0]}:${value[Object.keys(value)[0]]}`;
                }
                return value;
            })
            .join(",");

        const queryString =
            selectedValues &&
            selectedValues
                .map((value, index) =>
                    typeof value === "object" && value !== null
                        ? `key[${index}]=${Object.keys(value)[0]}:${value[Object.keys(value)[0]]
                        }`
                        : `key[${index}]=categories:${value}`
                )
                .join("&");
        if (updateUrl)
            newUrlAfterFilter.searchParams.set("category", queryStringCategoryParam);
        window.history.pushState({}, "", newUrlAfterFilter);
        if (searchQuery.length > 0 && queryString.length > 0) {
            setOptions([]);
            dispatch(
                getSearch({
                    searchQuery,
                    queryString,
                    page,
                    oAuthToken: localStorage.getItem("oAuthToken"),
                })
            );
        } else if (searchQuery.length > 0 && queryCategory.length === 0) {
            dispatch(
                getSearch({
                    searchQuery,
                    page,
                    oAuthToken: localStorage.getItem("oAuthToken"),
                })
            );
        } else if (searchQuery.length > 0 && queryCategory.length > 0) {
            const queryStringArray = splitQueryCategory(queryCategory);
            const queryString = queryStringFromArray(queryStringArray);
            dispatch(
                getSearch({
                    searchQuery,
                    queryString,
                    page,
                    oAuthToken: localStorage.getItem("oAuthToken"),
                })
            );
        }
    }

    function splitQueryCategory(queryCategory) {
        // Split the string by comma
        const parts = queryCategory.split(",");
        // Process each part
        const queryCategoryArray = parts.map((part) => {
            // If the part contains a colon, it's a key-value pair
            if (part.includes(":")) {
                const [key, value] = part.split(":");
                // Convert to an object
                return { [key.trim()]: parseInt(value.trim(), 10) };
            }
            // Otherwise, it's a simple string
            return part.trim();
        });
        return queryCategoryArray;
    }

    function queryStringFromArray(queryCategoryObj) {
        const queryString =
            queryCategoryObj &&
            Array.isArray(queryCategoryObj) &&
            queryCategoryObj.length > 0 &&
            queryCategoryObj
                .map((value, index) =>
                    typeof value === "object" && value !== null
                        ? `key[${index}]=${Object.keys(value)[0]}:${value[Object.keys(value)[0]]
                        }`
                        : `key[${index}]=categories:${value}`
                )
                .join("&");
        return queryString;
    }

    const style = {
        content: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#CDEBFF",
            opacity: "0.85",
        },
    };

    return (
        <>
            <Header />
            <Box
                className={classes.bannerimgBox}
            >
                <Grid container spacing={4} style={style.content}>
                    <Link style={{ zIndex: 1 }} to={-2}>
                        <div className={classes.backArrow}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                            >
                                <path
                                    d="M20 11.1562H7.83L13.42 5.56625L12 4.15625L4 12.1562L12 20.1562L13.41 18.7463L7.83 13.1562H20V11.1562Z"
                                    fill="#000000"
                                />
                            </svg>
                            <div className={classes.backTxt}>Back</div>
                        </div>
                    </Link>
                    <Grid item xs={6} xsOffset={1} className={classes.BannerItem1}>
                        <h1 className={classes.BannerHeading}>
                            {linkTitle ? linkTitle : ""}
                        </h1>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.BannerItem2}>
                            {""}
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.newResultPanel}>
                <div
                    style={{
                        width: "100%",
                    }}
                >
                    {loading ? (
                        <Loader />
                    ) : search && fetched ? (
                        search.total_records > 0 ? (
                            <>
                                {search.search_results &&
                                    search.search_results.map((search_result, index) => (
                                        <Link
                                            key={index}
                                            to={search_result.detail_page_api_link}
                                            state={search_result}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <Box
                                                className={classes.resultArticle}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <div className={classes.resultArticleHeading}>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: search_result.title,
                                                        }}
                                                    ></span>
                                                    <div className={classes.resultArticleInfo}>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: search_result.department + " ",
                                                            }}
                                                        ></span>{" "}
                                                        |
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: " " + search_result.type + " ",
                                                            }}
                                                        ></span>{" "}
                                                        |
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: " " + search_result.created_on,
                                                            }}
                                                        ></span>
                                                    </div>
                                                    <div
                                                        className={classes.resultArticleTxt}
                                                        dangerouslySetInnerHTML={{
                                                            __html: search_result.body,
                                                        }}
                                                    ></div>
                                                </div>
                                            </Box>
                                        </Link>
                                    ))}
                            </>
                        ) : (
                            <div className={classes.resulttxt}>
                                No result found for
                                <div style={{ color: theme.palette.primary.main }}>
                                    "{srhKey}"
                                </div>
                            </div>
                        )
                    ) : null}
                    {search &&
                        Object.keys(search)?.length > 0 &&
                        search.search_results && (
                            <SearchPagination
                                totalResults={search.total_records}
                                resultsPerPage={20}
                                page={page}
                                setPage={setPage}
                            />
                        )}
                </div>
            </Box>
            <Footer />
        </>
    );
}
