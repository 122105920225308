import React from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Box } from "@mui/material";
import styles from "./styles";

export default function AttachmentViewer(props) {
    const { classes } = styles();
    const fileUrl = props.attachment && props.attachment;
    const fileName = fileUrl && fileUrl.split('/').pop();
    const fileExtension = fileUrl && fileUrl.split('.').pop();
    const thumbnailUrl = props.thumbnail && props.thumbnail;
    const thumbnailName = thumbnailUrl && thumbnailUrl.split('/').pop();
    const docs = [{ uri: fileUrl, fileType: fileExtension, fileName: fileName }];

    return (
        <>
            {thumbnailUrl &&
                (
                    <>
                        <div className={classes.thumbnailName}>{thumbnailName}</div>
                        <Box
                            xs={12}
                            className={classes.thumbnailContainer}
                        >
                            <div className={classes.thumbnailImgBox}>
                                <img
                                    src={thumbnailUrl}
                                    alt={thumbnailName}
                                    className={classes.thumbnailImageStyles}
                                />
                            </div>
                        </Box>
                    </>
                )
            }
            {fileUrl && fileExtension !== 'pdf' && fileExtension !== 'PDF' &&
                <Box xs={12} sx={{ marginBottom: '60px' }}>
                    <DocViewer style={{ height: '600px', width: '100%' }} pluginRenderers={DocViewerRenderers} documents={docs} />
                </Box>
            }
            {fileUrl && fileExtension === 'pdf' &&
                <Box xs={12} sx={{ marginBottom: '60px' }}>
                    <object data={fileUrl} type="application/pdf" width="100%" height="600px"></object>
                </Box>
            }
            {fileUrl && fileExtension === 'PDF' &&
                <Box xs={12} sx={{ marginBottom: '60px' }}>
                    <iframe className={fileExtension} width="100%" height="600px" frameborder="0" title={fileName} src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}></iframe>
                    {/* <iframe className={fileExtension} width="100%" height="600px" frameborder="0" title={fileName} src={`https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`}></iframe> */}
                </Box>
            }
        </>
    );
}
