import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlArrowRight } from "react-icons/sl";
import * as msal from "@azure/msal-browser";
import "./style.scss";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Box, Container, useMediaQuery } from "@mui/material";
import Stack from "@mui/material/Stack";
import { getMegaMenu } from "./../../redux/actions/megaMenu/megaMenu";
import CustomTooltip from "./../Tooltip";
import { getApplicationsAssignedToUser } from "../../redux/actions/graph";
import { Link } from "react-router-dom";
import { getUserData } from "../../redux/actions/userData/userData";
import AppCard from "../AppCard";
import AppCardMenu from "../AppCard/menu.js";
import {
  fetchFavorites,
  addFavorite,
  removeFavorite,
} from "./../../redux/actions/graph/favorites";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import DepartmentIcon from "../../assets/images/apartment_icon_1.png";
import Chatbot from "../Chatbot/index.js";
export default function BasicMenu(props) {
  const {
    navLinksData,
    updateWhiteHeader,
    whiteHeaderFlag,
    scrolled,
    staticWhiteHeaderFlag,
    location,
    handleUpdateUrl,
    trainualPage
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { megaMenu, status, error } = useSelector((state) => state.megaMenu);
  const oAuthToken = localStorage.getItem("oAuthToken");
  const apps = useSelector((state) => state.apps.apps);
  const favoritesApps = useSelector((state) => state.getFavorites);
  const userId = useSelector((state) => state.userData.userData.id);
  const { userData } = useSelector((state) => state.userData);



  useEffect(() => {
    if (oAuthToken && !megaMenu?.length) {
      dispatch(getMegaMenu(localStorage.getItem("oAuthToken")));
    }
  }, [dispatch, oAuthToken, megaMenu?.length]);


  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexChild, setSelectedIndexChild] = useState(0);
  const [toggleMenu, setToggleMenu] = useState(false);

  const updateWhiteHeaderState = () => {
    setToggleMenu(!toggleMenu);
    updateWhiteHeader(toggleMenu);
  };

  useEffect(() => {
    if (whiteHeaderFlag === true) {
      setToggleMenu(false);
    }
  }, [whiteHeaderFlag]);

  const [showSubMenu, setShowSubMenu] = useState([]);

  const subMenuOnMouseEnterHandler = (subMenuId) => {
    setShowSubMenu((prev) => {
      let arr = [...prev];
      arr[subMenuId] = true;
      return arr;
    });
  };

  const [value, setValue] = useState(0);

  // Function to change value on hover
  const handleMouseOver = (index) => {
    setValue(index);
  };

  // Function to reset value when not hovering
  const handleMouseLeave = () => {
    setValue(0);
  };

  // Create a map to store unique entries based on the appId
  const uniqueApps = new Map();

  const allApps = new Map();

  // Extract category weights from megaMenu response
  const categoryWeights = React.useMemo(() => {
    if (megaMenu && megaMenu[0] && megaMenu[0].child) {
      return megaMenu[0].child.reduce((acc, item) => {
        acc[item.title] = parseFloat(item.weight);
        return acc;
      }, {});
    }
    return {};
  }, [megaMenu]);

  // Iterate over each object in the array
  for (const entry of apps) {
    // Add the entry to the map if the appId is unique
    let isHideApp = false;
    if (entry != undefined) {
      for (const tag of entry.tags) {
        if (tag.startsWith("HideApp")) {
          isHideApp = true;
        }
      }
      if (!isHideApp) {
        uniqueApps.set(entry.appId, entry);
      }
    }
  }

  for (const entry of apps) {
    if (entry != undefined) {
      allApps.set(entry.appId, entry);
    }
  }

  const uniqueCategories = new Map();
  let otherApps = [];

  uniqueApps.forEach((entry) => {
    let hasNonWinTag = false;

    for (const tag of entry.tags) {
      if (!tag.startsWith("HideApp")) {
        hasNonWinTag = true;
        const entries = uniqueCategories.get(tag) || [];
        entries.push(entry);
        uniqueCategories.set(tag, entries);
      }
    }

    if (entry != undefined && entry.tags.length == 0) {
      otherApps.push(entry);
    }
  });

  if (otherApps.length > 0) {
    uniqueCategories.set("Other Apps", otherApps);
  }

  // New function to get the weight of a category
  const getCategoryWeight = (category) => {
    return categoryWeights[category] !== undefined ? categoryWeights[category] : Infinity;
  };

  // Sort uniqueCategories by weight
  const sortedUniqueCategories = React.useMemo(() => {
    return new Map(
      [...uniqueCategories.entries()].sort((a, b) => {
        const weightA = getCategoryWeight(a[0]);
        const weightB = getCategoryWeight(b[0]);
        return weightA - weightB;
      })
    );
  }, [uniqueCategories, categoryWeights]);

  const hasStewbotStaging = Array.from(allApps.values()).some(
    (app) => app.displayName.includes("Stewbot Staging")
  );

  // Convert the map values back to an array
  // Convert the map values back to an array and sort by resourceDisplayName
  const safeSort = (a, b) => {
    const nameA = a?.displayName || "";
    const nameB = b?.displayName || "";
    return nameA.localeCompare(nameB);
  };

  // Convert the map values back to an array and sort by resourceDisplayName
  const filteredAllAppsData = Array.from(uniqueApps.values()).sort(safeSort);

  // Function to check if an appId is already a favorite
  const isFavorite = (appId) => {
    // Iterate over each object in the getFavorites array
    if (
      null != favoritesApps.getFavorites &&
      undefined != favoritesApps.getFavorites
    ) {
      // Check if the favorites array in this object contains the appId
      if (favoritesApps.getFavorites.favorites?.includes(appId)) {
        return true; // AppId is found, so it's a favorite
      }
    }
    return false; // AppId is not found in any favorites
  };

  const handleFavoriteChangeInMenu = (appId, isFavorite) => {
    if (!isFavorite) {
      dispatch(addFavorite({ userId, appId })).then((res) => {
        if (!res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    } else {
      dispatch(removeFavorite({ userId, appId })).then((res) => {
        if (!res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    }
  };

  const handleCardClick = (event) => {
    // Prevent the event from propagating to the parent elements
    event.stopPropagation();
    // Optional: You can handle additional actions here if needed
  };

  const closeMenu = () => {
    setToggleMenu(false);
    updateWhiteHeader(true);
    handleUpdateUrl && handleUpdateUrl(false);
  };

  const isMobile = useMediaQuery("(max-width:768px)");

  const scrollContainerRef = useRef(null);
  const [preventScroll, setPreventScroll] = useState(true);

  const handleScrollRight = () => {
    if (scrollContainerRef.current && isMobile && preventScroll) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
    setPreventScroll(!preventScroll);
    setSelectedIndexChild(-1);
  };

  const handleScrollLeft = () => {
    if (scrollContainerRef.current && isMobile) {
      scrollContainerRef.current.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <nav style={{ width: isMobile ? "14%" : "inherit" }}>
      <ul className="menu menu-bar">
        <li>
          {isMobile ? (
            <Link
              onClick={updateWhiteHeaderState}
              className="menu-link menu-bar-link"
              aria-haspopup="true"
              style={{
                color: trainualPage ? theme.palette.common.grey :
                  (!location.pathname.startsWith("/Search") &&
                    !toggleMenu &&
                    !scrolled
                    ? theme.palette.common.white
                    : theme.palette.common.grey),
                width: isMobile ? "20%" : "100%",
                padding: "5px 10px 10px 0px"
              }}
            >
              <MenuIcon />
            </Link>
          ) : (
            <Link
              onClick={updateWhiteHeaderState}
              className="menu-link menu-bar-link"
              aria-haspopup="true"
              style={{
                color:
                  !location.pathname.startsWith("/Search") &&
                    !toggleMenu &&
                    !scrolled
                    ? theme.palette.common.white
                    : theme.palette.common.white,
                fontSize: "18px",
                padding: "5px 0px 10px 30px",
              }}
            >
              Menu
            </Link>
          )}
          {toggleMenu && (
            <ul
              className="mega-menu mega-menu--multiLevel"
              onClick={(e) => isMobile && handleCardClick(e)}
            >
              <section style={{
                maxHeight: "75vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "none"
              }}>
                <li class="mobile-menu-back-item-home">
                  <Link to="/" class="menu-link mobile-menu-back-link" onClick={(e) => closeMenu()}>Home</Link>
                </li>
                {megaMenu[0]?.child?.map((el, i) => {
                  if (!el.child && el.title === 'Departments') {
                    return (
                      <li key={el.id}>
                        <Link
                          to="#"
                          //className="menu-link menu-list-link"
                          className={`menu-link menu-list-link ${selectedIndex === i ? "active" : ""}`}
                          aria-haspopup="true"
                        >
                          <Stack direction="row" alignItems="center" gap={1}>
                            <img src={el.menu_icon} /> {el.title}
                          </Stack>
                        </Link>
                      </li>
                    );
                  }
                  else if (el.title === 'Departments') {
                    return (
                      <>
                        <li
                          onMouseEnter={(event) =>
                            subMenuOnMouseEnterHandler(el.id)
                          }
                          //onMouseLeave={(event) => subMenuOnMouseLeaveHandler(el.id)}
                          key={el.id}
                        >
                          <Link
                            //  to="javascript:void(0);"
                            //className="menu-link mega-menu-link"
                            className={`menu-link menu-list-link ${selectedIndex === i ? "active" : ""}`}
                            aria-haspopup="true"
                            onMouseOver={() => setSelectedIndex(i)}
                            onMouseLeave={() => setSelectedIndex(-1)}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Stack direction="row" alignItems="center" gap={1}>
                              {/* <img src={DepartmentIcon} /> {el.title} */}
                              <ApartmentIcon /> {el.title}
                            </Stack>
                          </Link>
                          <ul key="department" className="menu menu-list department" ref={scrollContainerRef}>
                            <Container
                              sx={{
                                maxHeight: "80vh",
                                flexWrap: "wrap",
                                overflowY: "auto",
                                direction: isMobile ? "" : "rtl",
                                width: "107%",
                                scrollbarWidth: "none"
                                //height:"60%"
                              }}
                              onClick={() => handleScrollRight()}
                            >
                              {showSubMenu[el.id] &&
                                el.child.map((ele, index) => {
                                  return (
                                    <li
                                      className="dept-menu"
                                      onMouseEnter={() =>
                                        subMenuOnMouseEnterHandler(ele.id)
                                      }
                                      key={ele.id}
                                      style={{ width: isMobile ? "80%" : "100%" }}
                                    >
                                      <Link
                                        // to={ele.detail_page_api_link}
                                        to={(ele.title === "StewBot") ? "/stewbot" : ele.detail_page_api_link}
                                        state={ele}
                                        className={`menu-link menu-list-link ${selectedIndexChild === index ? "active" : ""}`}
                                        aria-haspopup="true"
                                        onMouseOver={() =>
                                          setSelectedIndexChild(index)
                                        }
                                        onMouseLeave={() =>
                                          setSelectedIndexChild(-1)
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          closeMenu();
                                        }}
                                      >
                                        <span>{ele.title}</span>{" "}
                                        {!isMobile && <SlArrowRight
                                          className="arrow-icon"
                                          style={{ float: "right", paddingRight: "3%" }}
                                        />}
                                      </Link>
                                      <ul
                                        animate={
                                          showSubMenu[ele.id] ? "open" : "closed"
                                        }
                                        className="menu menu-list thirdChild"
                                      >
                                        <Container
                                          sx={{
                                            maxHeight: "80vh",
                                            flexWrap: "wrap",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                            direction: "ltr",
                                            scrollbarWidth: "none"
                                            //height:"60%"
                                          }}
                                        >
                                          <Box
                                            component="div"
                                            key={ele.id}
                                            className="description"
                                            sx={{ pt: 4, px: 4, width: "90%" }}
                                          >
                                            <Link
                                              to={ele.detail_page_api_link}
                                              state={ele}
                                              className="dec-heading"
                                            >
                                              {ele.title}
                                            </Link>
                                            <p>{ele.description}</p>
                                            <p className="dec-related">
                                              {ele.related_heading}
                                            </p>
                                          </Box>
                                          <Box
                                            component="div"
                                            key={ele.id}
                                            className="description"
                                            sx={{ pb: 4, px: 4, width: "90%" }}
                                          >
                                            <ul style={{ height: "auto" }}>
                                              {showSubMenu[ele.id] &&
                                                ele.child &&
                                                ele.child.map((elem) => {
                                                  return (
                                                    <li>
                                                      <Link
                                                        to={
                                                          elem.detail_page_api_link
                                                            ? elem.detail_page_api_link
                                                            : elem.detail_link
                                                        }
                                                        target={
                                                          elem.detail_link !== elem.menu_url
                                                            ? ""
                                                            : "_blank"
                                                        }
                                                        state={elem}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          closeMenu();
                                                        }}
                                                      >
                                                        {elem.title}
                                                      </Link>
                                                    </li>
                                                  );
                                                })}
                                            </ul>
                                          </Box>
                                        </Container>
                                        {isMobile && <li class="mobile-menu-back-item">
                                          <a to="javascript:void(0);" class="menu-link mobile-menu-back-link" onClick={(e) => handleScrollLeft()}>Back</a>
                                        </li>}
                                      </ul>
                                    </li>
                                  );
                                })}
                            </Container>
                            <li class="mobile-menu-back-item">
                              <a to="javascript:void(0);" class="menu-link mobile-menu-back-link" onClick={(e) => closeMenu()}>Back</a>
                            </li>
                          </ul>
                        </li>
                      </>
                    );
                  }
                })}
                {[...sortedUniqueCategories.entries()].map(
                  ([category, items], i) => {
                    //const appRole = app.appRoles;
                    if (Array.isArray(items)) {
                      return (
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="menu-link mega-menu-link"
                            //className={`menu-link menu-list-link ${selectedIndex === i ? "active" : ""}`}
                            aria-haspopup="true"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Stack direction="row" alignItems="center" gap={1}>
                              <ApartmentIcon /> {category}
                            </Stack>
                          </a>
                          <ul key="apps" className="menu menu-list custom">
                            <Container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                overflowY: "auto",
                                scrollbarWidth: "none",
                                maxHeight: "75vh"
                                //height:"60%"
                              }}
                            >
                              {items.map((item, index) => (
                                <AppCardMenu
                                  onMouseOver={() => handleMouseOver(index)}
                                  onMouseLeave={() => handleMouseLeave()}
                                  value={value}
                                  key={item.appId}
                                  app={item}
                                  index={index}
                                  userId={userId}
                                  isFavorite={isFavorite(item.appId)}
                                  onFavoriteChange={() =>
                                    handleFavoriteChangeInMenu(
                                      item.appId,
                                      isFavorite(item.appId)
                                    )
                                  }
                                  handleCardClick={handleCardClick}
                                />
                              ))}
                            </Container>
                            <li class="mobile-menu-back-item">
                              <a to="javascript:void(0);" class="menu-link mobile-menu-back-link" onClick={(e) => closeMenu()}>Back</a>
                            </li>
                          </ul>
                        </li>
                      );
                    }
                  }
                )}
                <li>
                  <a
                    href="javascript:void(0);"
                    className="menu-link mega-menu-link"
                    //className={`menu-link menu-list-link ${selectedIndex === i ? "active" : ""}`}
                    aria-haspopup="true"
                    onClick={(e) => { e.stopPropagation(); }}
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <ApartmentIcon /> All Apps
                    </Stack>
                  </a>
                  <ul className="menu menu-list apps">
                    <Container
                      sx={{
                        display: "flex",
                        // height: "100px",
                        paddingLeft: "0px !important",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        paddingBottom: "20px",
                      }}
                    >
                      {filteredAllAppsData &&
                        filteredAllAppsData.map((app, index) => {
                          //const appRole = app.appRoles;
                          if (app.appId !== null) {
                            return (
                              <AppCard
                                key={app.appId}
                                app={app}
                                userId={userId}
                                isFavorite={isFavorite(app.appId)}
                                onFavoriteChange={() =>
                                  handleFavoriteChangeInMenu(
                                    app.appId,
                                    isFavorite(app.appId)
                                  )
                                }
                                handleCardClick={handleCardClick}
                              />
                            );
                          }
                        })}
                    </Container>
                    <li class="mobile-menu-back-item">
                      <a to="javascript:void(0);" class="menu-link mobile-menu-back-link" onClick={(e) => closeMenu()}>Back</a>
                    </li>
                  </ul>
                </li>
              </section>
            </ul>
          )}
        </li>
      </ul>
      {hasStewbotStaging && <Chatbot />}
    </nav>
  );
}
